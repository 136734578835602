import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import { Layout } from '../components/layout'
import SEO from '../components/seo'
import {
  Grid,
  Typography,
  makeStyles,
  Divider,
  Container,
} from '@material-ui/core'
import { ProductCard } from '../components/ProductCard'
import { normalizedProducts } from '../state/app'

interface Props {
  data: any
}

interface DispatchToProps {
  setProducts: (payload: any) => void
}

interface StateToProps {
  cart: any
}

const useStyles = makeStyles({
  root: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  media: {
    height: 300,
    width: '100%',
  },
  card: {
    width: 337,
    height: 430,
  },
})

const Store: React.FC<StateToProps & DispatchToProps & Props> = ({
  data,
  cart,
  setProducts,
}) => {
  const classes = useStyles()
  const [NoOfPost, updateNoOfPost] = React.useState(6)
  const [contentfulProducts, updateContentfulProducts] = React.useState(
    data.allContentfulProduct.edges
  )

  React.useEffect(() => {
    updateContentfulProducts(data.allContentfulProduct.edges)
  }, [data])

  React.useEffect(() => {
    console.log('data', data.allContentfulProduct.edges)
  }, [data])

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  }, [])

  const handleScroll = () => {
    var lastScrollY = window.pageYOffset + 1100

    if (lastScrollY > window.outerHeight) {
      const count = NoOfPost + 3

      updateNoOfPost(count)
    }
  }

  return (
    <Layout>
      <SEO
        title="Store"
        keywords={[
          `WSH entertainment`,
          `streetwear`,
          `fashion`,
          `videography`,
          `atlanta fashion`,
          `new york fashion`,
        ]}
      />
      <Container>
        <Typography
          gutterBottom
          variant={'h1'}
          align={'center'}
          style={{ margin: '32px' }}
        >
          Merchandise
        </Typography>
        <Divider />
        <Grid container justify={'center'} spacing={5} className={classes.root}>
          {contentfulProducts.map((p: any) => {
            console.log('p', p)

            return (
              <Grid key={p.node.id} item sm={4}>
                <ProductCard
                  id={p.node.id}
                  slug={p.node.slug}
                  image={p.node.image.fixed.src}
                  name={p.node.name}
                  price={p.node.price}
                />
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </Layout>
  )
}

export default connect<StateToProps, DispatchToProps, Props, {}>(
  (state: any) => ({
    cart: state.app.cart,
  }),
  (dispatch: any) => ({
    setProducts: (payload: any) => dispatch(normalizedProducts(payload)),
  })
)(Store)

export const query = graphql`
  query StoreQuery {
    allContentfulProduct {
      edges {
        node {
          id
          name
          slug
          image {
            fixed(width: 1200, height: 1200) {
              width
              height
              src
              srcSet
            }
          }
          price
          stripeProducts {
            products {
              priceId
              size
            }
          }
          details {
            childMarkdownRemark {
              excerpt(pruneLength: 140)
            }
          }
        }
      }
    }
  }
`
